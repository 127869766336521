/*
 * @Author: xiaot
 * @Date: 2022-01-10 15:57:14
 * @Description:
 * @FilePath: /front-public/packages/work-platform/src/common/utils.ts
 */
import cookie from 'js-cookie';
import { FlowActions, action2InterfaceKey, flowActionText } from './const';
import { WorkPlatformService } from '@WorkPlatform/api';

/**
 * 清空storage
 * @param store
 */
export function removeStorage () {
  // 工作台
  removeCookie('queryMsg');
  removeCookie('expireTime');
  localStorage.removeItem('expireTime');
  removeCookie('access_token');
  localStorage.removeItem('token');
  localStorage.removeItem('corpInfo');
  localStorage.removeItem('userInfo');
  localStorage.removeItem('user');
  localStorage.removeItem('appCode');
  localStorage.removeItem('locale');
  localStorage.removeItem('perm');
  localStorage.removeItem('IS_FREELOGIN');

  // 开发平台
  localStorage.removeItem('debug_request_id');
  localStorage.removeItem('showStyleA');
  localStorage.removeItem('showStyleP');
  localStorage.removeItem('sortTypeA');
  localStorage.removeItem('sortTypeP');
  localStorage.removeItem('assetSSO');

  localStorage.removeItem('tenants');
  localStorage.removeItem('tenantCode');
  sessionStorage.removeItem('tenantCode');
  removeCookie('tenantCode');

  const storage: string[] = Object.keys(localStorage);
  storage.forEach(item => {
    if (item.indexOf('RECENT_APP_IDS') > -1) {
      localStorage.removeItem(item);
    }
  });
}

/** 清空token */
export function removeToken () {
  removeCookie('access_token');
  localStorage.removeItem('token');
};

export function getTokenExpires (token:string) {
  if (!token) { return null; }
  const strings = token.split('.'); // 截取token，获取载体
  // 解析，需要把‘_’,'-'进行转换否则会无法解析
  const info = JSON.parse(decodeURIComponent(encodeURI(window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/')))));
  console.log(new Date(info.exp * 1000));
  const expires = new Date(info.exp * 1000).toUTCString();
  return expires;
}

/**
 * 去掉属性字符串前后空格
 * @param originalData
 */
export function trim (originalData: any, type: string = 'trimStrFrontEnd') {
  forEachObject(originalData, (data: any, key: string, value: any) => {
    if (typeof value === 'string') {
      let method: any = () => { };

      if (type === 'trimStr') {
        method = trimStr;
      } else if (type === 'trimStrFrontEnd') {
        method = trimStrFrontEnd;
      } else if (type === 'trimStrFront') {
        method = trimStrFront;
      } else if (type === 'trimStrEnd') {
        method = trimStrEnd;
      }

      const replaceValue: string = method(value);
      data[key] = replaceValue;
    }
  });
}

/**
 * 递归遍历对象
 * @param data
 * @param callback
 * @returns
 */
export function forEachObject (data: any, callback: Function) {
  if (!data) return data;

  const keys: string[] = Object.keys(data);

  for (let i = 0, len = keys.length; i < len; i++) {
    const key: string = keys[i];
    const value: any = data[key];

    if (value instanceof Object || value instanceof Array) {
      forEachObject(value, callback);
    } else {
      callback(data, key, value);
    }
  }
  return false;
}

/**
 * 去除字符串前后空格
 * @param v
 * @returns
 */
export function trimStrFrontEnd (v: string) {
  return v.replace(/^(?:(\s)|(\s))$/, '');
}

/**
 * 去除字符串前空格
 * @param v
 * @returns
 */
export function trimStrFront (v: string) {
  return v.replace(/^\s/, '');
}

/**
 * 去除字符串前空格
 * @param v
 * @returns
 */
export function trimStrEnd (v: string) {
  return v.replace(/\s$/, '');
}

/**
 * 去除字符串所有空格
 * @param v
 * @returns
 */
export function trimStr (v: string) {
  return v.replace(/\s/, '');
}

/**
 * 从refs中获取指定的组件
 * @param refs
 * @param name
 * @returns
 */
export const getComponentByRefs = (refs: any, name: string) => {
  return new Promise((resolve: (value: unknown) => void) => {
    const id: number = setInterval(() => {
      if (refs[name]) {
        clearInterval(id);

        resolve(refs[name]);
      }
    });
  });
};

/**
 * 判断是否是PC
 */
export function isPC () {
  const userAgentInfo: string = navigator.userAgent;
  const Agents: string[] = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  let flag: boolean = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) >= 0) {
      flag = false;
      break;
    }
  }

  return flag;
}

/**
 * 判断是否是移动端
 */
export function isPhone () {
  // eslint-disable-next-line max-len
  const reg: RegExp = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;

  const mobile = navigator.userAgent.match(reg);
  return mobile != null;
}

/**
 * 睡眠
 * @param time
 * @returns
 */
export function sleep (time: number) {
  return new Promise((resolve: (value: unknown) => void) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
}

/**
 * 获取图片地址
 * 兼容旧版图片拼接地址
 * 现阶段图片直接为请求地址不需要处理
 * @param img
 * @returns
 */
export const getImageUrl = (img) => {
  if (img.startsWith('http://') || img.startsWith('https://')) {
    return img;
  } else {
    const ENV_ORIGIN = localStorage.getItem('ENV_ORIGIN') || '';
    const refUrl = '/api/webapi/file/common/download?refId=';
    return img ? (
      img.includes(refUrl) ? `${ENV_ORIGIN}${img}&accessToken=${localStorage.getItem('token')}` : img
    ) : '';
  }
};

export function isDingTalk () {
  return sessionStorage.IS_DINGTALK === 'true';
}

export function isFreeLoginPlatform () {
  return sessionStorage.IS_DINGTALK === 'true' || sessionStorage.IS_WECHAT === 'true';
}

export function isWeChat () {
  return sessionStorage.IS_WECHAT === 'true';
}

// 定义一个深拷贝函数  接收目标target参数
export function deepClone<T extends Object> (target: T): T {
  // 定义一个变量
  let result: any;
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === 'object') {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      result = []; // 将result赋值为一个数组，并且执行遍历
      for (const i in target) {
        if (Object.prototype.hasOwnProperty.call(target, i)) {
          // 递归克隆数组中的每一项
          result.push(deepClone(target[i]));
        }
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if (target === null) {
      result = null;
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if (target.constructor === RegExp) {
      result = target;
    } else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      result = {};
      for (const i in target) {
        // 判断属性是否存在于对象实例中
        if (Object.prototype.hasOwnProperty.call(target, i)) {
          result[i] = deepClone(target[i] as any);
        }
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target;
  }
  // 返回最终结果
  return result;
}

export function parseQuery (url: string) {
  const vars: any = {};
  let hash: string[];
  let urls = decodeURI(url).replace(window.location.origin, '');

  const jhIndex = urls.indexOf('#');
  if (jhIndex > -1) {
    urls = urls.substr(jhIndex);
  }

  const index: string[] = urls.split('?');

  let hashes: string[] = [];
  if (index.length > 1) {
    hashes = index[1].split('&');
    for (let i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      vars[hash[0]] = decodeURIComponent(hash[1]);
    }
  }
  return vars;
}

/**
 * 统一设置cookie方法
 */
export function setCookie (
  name: string,
  value: string | object,
  options?: cookie.CookieAttributes,
) {
  const ENV_ORIGIN = localStorage.getItem('ENV_ORIGIN');
  let domain = '';
  if (ENV_ORIGIN) {
    domain = ENV_ORIGIN.split('//')[1];
  } else {
    domain = location.host.split(':')[0];
  }
  if (location.protocol.includes('https')) {
    cookie.set(name, value, {
      ...options,
      domain,
      sameSite: 'None',
      secure: true,
    });
  } else {
    cookie.set(name, value, {
      ...options,
      domain,
    });
  }
}

/**
 * 统一清除cookie方法
 */
export function removeCookie (
  name: string,
  options?: cookie.CookieAttributes,
) {
  const ENV_ORIGIN = localStorage.getItem('ENV_ORIGIN');
  let domain = '';
  if (ENV_ORIGIN) {
    domain = ENV_ORIGIN.split('//')[1];
  } else {
    domain = location.host.split(':')[0];
  }
  if (location.protocol.includes('https')) {
    cookie.remove(name, {
      ...options,
      path: '/',
      domain,
      sameSite: 'None',
      secure: true,
    });
  } else {
    cookie.remove(name, {
      ...options,
      path: '/',
      domain,
    });
  }
}

/** 通过数据接口获取流程操作文本 */
export async function getFlowActionText () {
  const isExited = sessionStorage.getItem(flowActionText);
  if (isExited) return;

  const { success, data } = await WorkPlatformService.getFlowActionText();

  if (success && data) sessionStorage.setItem(flowActionText, JSON.stringify(data));
}

/**
 * 获取按钮及操作文本
 */
export function getActionTextFromStorage (actionType: FlowActions) {
  let actionText: { [key: string]: string } | string = sessionStorage.getItem(flowActionText) || '';
  actionText = actionText ? JSON.parse(actionText) : {};

  return actionText[action2InterfaceKey[actionType]];
}
